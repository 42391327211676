import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Home from './pages/home';
import Game from './pages/game';
import Profile from './pages/profile';
import Login from './pages/login';
import PhraseBook from './pages/phrasebook';
import Phrases from './pages/phrases';
import Collection from './pages/collection';
import Language from './pages/language';
import Settings from './pages/settings';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './util/i18n';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        <Route path="/game" element={<Game />} />
        <Route path="/phrasebook" element={<PhraseBook />} />
        <Route path="/phrases" element={<Phrases />} />
        <Route path="/collection" element={<Collection />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/login" element={<Login />} />
        <Route path="/language" element={<Language />} />
        <Route path="/settings" element={<Settings />} />
      </Route>
    </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
