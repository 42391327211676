import './App.css';
import { Outlet } from 'react-router-dom';
import AuthContextProvider from './util/authcontextprovider';
import { createContext, useState, useContext, useMemo } from 'react';

function App() {
  const [user, setUser] = useState({});
  const [language, setLanguage] = useState({});
  const uservalue = useMemo(
    () => ({ user, setUser }),
    [user]
  );
  const languagevalue = useMemo(
    () => ({ language, setLanguage }),
    [language]
  );

  return (
    <AuthContextProvider value={{uservalue: [user, setUser], languagevalue: [language, setLanguage]}}><div className="App">
    </div><Outlet />
    </AuthContextProvider>
  );
}

export default App;