import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import React, { useState, useEffect } from 'react';
import correctsound from '../assets/sounds/correct.mp3';
import incorrectsound from '../assets/sounds/notcorrect.mp3';
import styles from './selectimage.module.css';

export default function SelectImage(props) {

    const words = props.words;
    const word = props.word;
    const color = props.color;
    const soundenabled = props.soundenabled;

    const [allwords, setAllWords] = useState([]);
    const [rightindex, setRightIndex] = useState(-1);
    const [correctaudio, setCorrectAudio] = useState();
    const [incorrectaudio, setInCorrectAudio] = useState();
    const [itemclicked, setItemClicked] = useState(-1);

    useEffect(() => {
      const _otherwords = words.filter(_word => _word.order != word.order);
      const _allwords = shuffle(_otherwords).slice(0,3);
      const _rightindex = Math.floor(Math.random() * 4);
      _allwords.splice(_rightindex, 0, word);
      setAllWords(_allwords);
      setRightIndex(_rightindex);
      if (soundenabled) {
        setCorrectAudio(new Audio(correctsound));
        setInCorrectAudio(new Audio(incorrectsound));
      }
    }, []);

    function increaseOrder(iswrong) {
        let order = props.order;
        order++;
        props.onNextExercise(order, iswrong, "selectimage");
    }

    function checkAnswer(selectedindex) {
        if (selectedindex == rightindex) {
            if (correctaudio != null) {
                correctaudio.play();
              }
            increaseOrder();
        }
        else {
            if (incorrectaudio != null) {
                incorrectaudio.play();
              }
            setItemClicked(selectedindex);
            setTimeout(function () {
                increaseOrder(true);
          }, 600);
        }
    }

    function shuffle(array) {
        let currentIndex = array.length, randomIndex;

        while (currentIndex != 0) {

          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
 
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }

    return(
        <Stack spacing={10}>
            <h2>Click for translation.</h2>
            <h1>{word.name}</h1>
            <Stack spacing={4}>
                <Stack direction="row" spacing={4}>
                    <Button className={styles.selectimagebutton + (itemclicked == 0 ? " " + styles.wrong : "")} onClick={(e) => checkAnswer(0, e)} sx={{
                        borderRadius: 2,
                        height: 192,
                        width: 192,
                        bgcolor: color + '.dark',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center', ':hover': {
                            bgcolor: color + '.light'
                          }
                    }}>
                        {allwords.length > 0 && 
                    <img className={styles.selectimage} src={require('../assets/' + allwords[0].image + '.png')}>
                    </img>
                    }
                </Button>
                        <Button className={styles.selectimagebutton + (itemclicked == 1 ? " " + styles.wrong : "")} onClick={(e) => checkAnswer(1, e)} sx={{
                            borderRadius: 2,
                            height: 192,
                            width: 192,
                            bgcolor: color + '.dark',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center', ':hover': {
                                bgcolor: color + '.light'
                              }
                        }}>
                        {allwords.length > 0 && 
                    <img className={styles.selectimage} src={require('../assets/' + allwords[1].image + '.png')}>
                    </img>
                    }
                        </Button>
                    </Stack>
                <Stack direction="row" spacing={4}>
                    <Button className={styles.selectimagebutton + (itemclicked == 2 ? " " + styles.wrong : "")} onClick={(e) => checkAnswer(2, e)} sx={{
                        borderRadius: 2,
                        height: 192,
                        width: 192,
                        bgcolor: color + '.dark',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center', ':hover': {
                            bgcolor: color + '.light'
                          }
                    }}>
                        {allwords.length > 0 && 
                    <img className={styles.selectimage} src={require('../assets/' + allwords[2].image + '.png')}>
                    </img>
                    }
                        </Button>
                        <Button className={styles.selectimagebutton + (itemclicked == 3 ? " " + styles.wrong : "")} onClick={(e) => checkAnswer(3, e)} sx={{
                            borderRadius: 2,
                            height: 192,
                            width: 192,
                            bgcolor: color + '.dark',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center', ':hover': {
                                bgcolor: color + '.light'
                              }
                        }}> 
                        {allwords.length > 0 && 
                    <img className={styles.selectimage} src={require('../assets/' + allwords[3].image + '.png')}>
                    </img>
                    }
                         </Button>
                    </Stack>
            </Stack>
            <Stack sx={{ textAlign: 'center' }} spacing={4}>
            </Stack>
        </Stack>
    )
}