import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCGAY0cicggcz7qXHy3uLx0ktsZy9ehjHQ",
  authDomain: "dopa-d454d.firebaseapp.com",
  projectId: "dopa-d454d",
  storageBucket: "dopa-d454d.appspot.com",
  messagingSenderId: "832476491572",
  appId: "1:832476491572:web:da92fd6df1e28aa1b03f61",
  measurementId: "G-NWH15ECD15"
};

initializeApp(firebaseConfig);
const db = getFirestore();

export default db;