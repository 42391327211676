export const Colors = ['#f4aca5', '#ba68c8', '#64b4f6', '#7e57c2', '#60b3d1', '#4cc4d9', '#9ccc65', '#9e9d24', '#ffb300', '#f2c933', '#ff8965', '#ff7043', '#f4aca5', '#ef5350'];
export const DarkColors = ['#fd9598', '#45b7da', '#8ab241', '#f9a825'];
export const LightColors = ['#00000011'];
export const TintColor = 'rgba(0,0,0,0.5)';
export const OtherColors = ['#ffe7c5', '#ee5e5f', '#a3f4e2'];
export const OtherColorsDark = ['#c2ad97', '#ba4a4a', '#4df0cc'];
export const Languages = [{code: 'en', name: 'English'}, {code: 'tr', name: 'Türkçe'}];
export const SourceLanguages = [{code: 'en', name: 'English'}, {code: 'tr', name: 'Türkçe'}];
export const MAX_LIVES = 4;
export const INSTAGRAM_URL = "https://www.instagram.com/fonoyayinlari";
export const YOUTUBE_URL = "https://www.youtube.com/channel/UCvLNTmPcdN4fYGetF_gzRPg";
export const TIKTOK_URL = "https://www.tiktok.com/@fonoyayinlari";
export const BADGE_THRESHOLDS = [10.0, 30.0, 50.0, 70.0];