import { useCallback, useState, useEffect } from "react";
import AuthContext from "./authcontext";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { doc, getDoc, setDoc } from "firebase/firestore";
import db from './firebase';
import { Languages, MAX_LIVES, SourceLanguages } from './constants';
import { useNavigate } from "react-router-dom";

const AuthContextProvider = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [language, setLanguage] = useState({languagecode: localStorage.getItem('Language_Code'), sourcelanguagecode: localStorage.getItem('Source_Language_Code')});
  const [user, setUser] = useState({});
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
            setIsInitialized(true);
            getProfile(user);
        } else {    
            setIsInitialized(true);
            setUser(null);
            navigate("/login");
        }
        });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (language?.languagecode != null && language?.sourcelanguagecode != null) {
      localStorage.setItem("Language_Code", language?.languagecode);
      localStorage.setItem("Source_Language_Code", language?.sourcelanguagecode);
    }
  }, [language]);

  const getProfile = async(user) => {
    let languagecode = language?.languagecode;
    let sourcelanguagecode = language?.sourcelanguagecode;
    
    if (languagecode != null && sourcelanguagecode != null) {
      if (sourcelanguagecode == language) {
        if (sourcelanguagecode == "tr") {
          languagecode = "en"
        }
        if (sourcelanguagecode == "en") {
          languagecode = "tr"
        }
      }
  
      setLanguage({languagecode: languagecode, sourcelanguagecode: sourcelanguagecode});  
    }
    else {
      let sourcelanguagecode = "tr"
      let languagecode = "en"

      setLanguage({languagecode: languagecode, sourcelanguagecode: sourcelanguagecode});
    }

    const docRef = doc(db, "users", user.uid, "profiles", languagecode);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        setUser({authuser: user, profile: docSnap.data()});
    } else {
        createUser(user);
        console.log("No such document!");
    }
  };

  const createUser = async(user) => {
    const docRef = doc(db, "users", user.uid, "profiles", language.languagecode);
    const data = {
        datesofactivity: null,
        finishedlessons: [],
        lastliveslossdate: null,
        lives: MAX_LIVES,
        percentage: 0.0,
        streak: 0,
        vocabularyCount: 0
      };
    const newDoc = await setDoc(docRef, data);

    if (newDoc.exists()) {
        setUser({authuser: user, profile: newDoc.data()});
    } 
  }

  const getContent = useCallback(() => {
    if (!isInitialized) {
      return <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={false}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    }
    return children;
  }, [isInitialized, user, children]);

  return <AuthContext.Provider value={{uservalue: [user, setUser], languagevalue: [language, setLanguage]}}>{getContent()}</AuthContext.Provider>;
};

export default AuthContextProvider;