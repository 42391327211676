import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useSpeechRecognition } from 'react-speech-kit';
import React, { useState, useEffect } from 'react';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import styles from './speech.module.css'
import correctsound from '../assets/sounds/correct.mp3';
import { browserName } from 'react-device-detect';
import ContinueButton from './continuebutton';

export default function Speech(props) {

  const words = props.words;
  const word = props.word;
  const wordname = word.name;
  const color = props.color;
  const languagecode = props.languagecode;
  const soundenabled = props.soundenabled;

  const [result, setResult] = useState(0);
  const [correctaudio, setCorrectAudio] = useState();
  const [issupported, setIsSupported] = useState(true);

  const { listen, listening, stop, supported } = useSpeechRecognition({
    onResult: (result) => {
      stop();
      compare(result);
    },
    onError: (error) => {
      console.log(error);
    }
  });

    const toggle = listening
    ? stop
    : () => {
        listen({ interimResults: false, lang: languagecode });
      };

    useEffect(() => {
      if (soundenabled) {
        setCorrectAudio(new Audio(correctsound));
      }

      if (browserName != "Chrome" && browserName != "Safari") {
        setIsSupported(false);
      }
    }, []);

    function increaseOrder() {
        let order = props.order;
        order++;
        props.onNextExercise(order);
    }

    function compare(result) {
      if (result.includes(wordname)) {
        setResult(1);
        if (correctaudio != null) {
          correctaudio.play();
        }
        setTimeout(function () {
          increaseOrder();
        }, 500);
      }
      else {
        setResult(2);
      }
    }

    if (issupported) {
    return(
        <Stack spacing={10} alignItems="center">
        <h2>Speak the word</h2>
        <h2>{wordname}</h2>
        <Box sx={{
            borderRadius: 1,
            height: 150,
            width: 150,
            bgcolor: color + '.dark',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }} >    
        <IconButton className={styles.speechbutton}
        sx={{bgcolor: color + '.dark', color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 1, width: 150, height: 150}} onClick={toggle}>
        {!listening && <MicIcon sx={{ fontSize: 80}} />} 
        {listening && <StopIcon sx={{ fontSize: 80}} />} 
        </IconButton>
        </Box>
        <Box sx={{
            height: 50,
            width: '%100',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }} > 
          {listening && <h2>I am listening...</h2>}
          {!listening && result == 1 && <h2>Perfect!</h2>}
          {!listening && result == 2 && <h2>Hmm.. Did't get that.</h2>}
        </Box>
        <ContinueButton color={color} onClick={increaseOrder} />
        </Stack>
    )
    }
    else {
        return(
          <Stack spacing={30} alignItems="center" sx={{marginTop: "128px"}}>
            <p>
            Oh no, it looks like your browser doesn&#39;t support Speech
            Recognition. Switch to Google Chrome or continue.
          </p>
          <ContinueButton color={color} onClick={increaseOrder} />
          </Stack>
        )
    }
}