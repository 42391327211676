import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import ExitButton from './exitbutton';
import Stack from '@mui/material/Stack';
import LivesText from './livestext';

export default function GamePanel(props) {
    const lives = props.lives;
    const percentage = ((props.wordindex + 1) * 100.0) / props.words.length;
    const color = props.color;

    return(
        <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box sx={{display: 'flex', flex: 1}}>
            <ExitButton color={color} onExit={props.exit} />
        </Box>
        <Box sx={{ width: {
            xs: "150px",
            sm: "300px"
         }
         }}>
            <LinearProgress sx={{bgcolor: color, '& .MuiLinearProgress-bar': {
            bgcolor: 'info.main'
            }, }} variant="determinate" value={percentage} />
        </Box>
        <Box sx={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
            <LivesText lives={lives} /></Box>
        </Stack>
    )
}
