import React, { useState, useEffect, useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ListSubheader from '@mui/material/ListSubheader';
import AuthContext from "../util/authcontext";
import Switch from '@mui/material/Switch';
import { INSTAGRAM_URL, YOUTUBE_URL, TIKTOK_URL, OtherColors, OtherColorsDark, SourceLanguages } from '../util/constants';

export default function Settings() {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const { uservalue, languagevalue } = useContext(AuthContext);
    const [user, setUser] = uservalue;
    const [language, setLanguage] = languagevalue;
    const [soundenabled, setSoundEnabled] = useState((localStorage.getItem("Sound_Enabled") ?? "true") === "true" ? true : false);
    const [speechenabled, setSpeechEnabled] = useState((localStorage.getItem("Speech_Enabled") ?? "true") === "true" ? true : false);
    const [dialogopen, setDialogOpen] = useState(false);

    function exit() {
        navigate("/");
    }

    function goToLanguage() {
        navigate("/language");
    }

    function handleClose() {
        setDialogOpen(false);
    }

    function handleSourceLanguageChange(value) {
        const languagecode = language?.languagecode
        if (value == languagecode) {
            if (value == "en") {
                setLanguage({languagecode: "tr", sourcelanguagecode: value})
            }
            else if (value == "tr") {
                setLanguage({languagecode: "en", sourcelanguagecode: value}) 
            }
        }
        else {
            setLanguage({languagecode: language?.languagecode, sourcelanguagecode: value})
        }
        handleClose()
    }

    function SourceLanguageDialog(props) {

        return (       
            <Dialog onClose={handleClose} open={props.open}>
            <DialogTitle>Ana dili seç</DialogTitle>
            <List sx={{ pt: 0 }}>
            {SourceLanguages.map((language) => (
                <ListItem button onClick={() => handleSourceLanguageChange(language.code)} key={language.code}>
                    <ListItemAvatar>
                        <img src={require('../assets/flags/' + language.code + 'flag.svg')} style={{ borderRadius: '50%' }} width='30' height='30' alt={`flag${language.code}`}></img>
                    </ListItemAvatar>
                <ListItemText primary={language.name} />
                </ListItem>
            ))}
            </List>
            </Dialog>
        )
    }

    function goToInstagram() {
        window.location.href = INSTAGRAM_URL;
    }

    function goToYoutube() {
        window.location.href = YOUTUBE_URL;
    }

    function goToTikTok() {
        window.location.href = TIKTOK_URL;
    }

    function handleSoundEffectChange() {
        const _soundenabled = !soundenabled;
        setSoundEnabled(_soundenabled);
        localStorage.setItem("Sound_Enabled", _soundenabled.toString());
    }

    function handleSpeechChange() {
        const _speechenabled = !speechenabled;
        setSpeechEnabled(_speechenabled);
        localStorage.setItem("Speech_Enabled", _speechenabled.toString());
    }

    useEffect(() => {
        document.body.style.background = OtherColors[2];
    }, []);

    return(
        <><IconButton aria-label="exit" size="large" sx={{ m: 4, backgroundColor: OtherColorsDark[2] }} onClick={exit}>
            <CloseIcon fontSize="inherit" />
        </IconButton>
        <Container sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                <SourceLanguageDialog open={dialogopen} />
                <Stack spacing={4} sx={{ width: '80%'}}>
                    <h2>Ayarlar</h2>
                    <List>
                        <ListSubheader disableSticky sx={{bgcolor: 'transparent', fontSize: 25, mb: 5}}>
                            GENEL
                        </ListSubheader>
                        <ListItem>
                            <ListItemButton onClick={goToLanguage} sx= {{ bgcolor: OtherColorsDark[2], borderRadius: 15}}>
                                <ListItemText disableTypography id='sourcelanguageitem1' primary={'Language'} sx={{ fontSize: 25, fontWeight: 500 }} />
                                <ListItemText disableTypography id='sourcelanguageitem2' primary={language?.languagecode} sx={{ ml: 5, fontSize: 25, textAlign: 'right' }} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton onClick={setDialogOpen} sx= {{ bgcolor: OtherColorsDark[2], borderRadius: 15}}>
                                <ListItemText disableTypography id='sourcelanguageitem1' primary={'Source Language'} sx={{ fontSize: 25, fontWeight: 500 }} />
                                <ListItemText disableTypography id='sourcelanguageitem2' primary={language?.sourcelanguagecode} sx={{ ml: 5, fontSize: 25, textAlign: 'right' }} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton onClick={handleSoundEffectChange} sx={{ bgcolor: OtherColorsDark[2], borderRadius: 15}}>
                                <ListItemText disableTypography id='soundeffectitem' primary={'Sound Effects'} sx={{ fontSize: 25, fontWeight: 500 }} />
                                <Switch color="success" checked={soundenabled} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton onClick={handleSpeechChange} sx={{ bgcolor: OtherColorsDark[2], borderRadius: 15}}>
                                <ListItemText disableTypography id='speechitem' primary={'Speech'} sx={{ fontSize: 25, fontWeight: 500 }} />
                                <Switch color="success" checked={speechenabled} />
                            </ListItemButton>
                        </ListItem>
                        <ListSubheader disableSticky sx={{bgcolor: 'transparent', fontSize: 25, mb: 5, mt: 5}}>
                            SOSYAL
                        </ListSubheader>
                        <ListItem>
                            <ListItemButton onClick={goToInstagram} sx= {{ bgcolor: OtherColorsDark[2], borderRadius: 15}}>
                                <ListItemText disableTypography id='instagramitem' primary={'Instagram'} sx={{ fontSize: 25, fontWeight: 500 }} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton onClick={goToYoutube} sx= {{ bgcolor: OtherColorsDark[2], borderRadius: 15}}>
                                <ListItemText disableTypography id='youtubeitem' primary={'Youtube'} sx={{ fontSize: 25, fontWeight: 500 }} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton onClick={goToTikTok} sx= {{ bgcolor: OtherColorsDark[2], borderRadius: 15}}>
                                <ListItemText disableTypography id='tiktokitem' primary={'TikTok'} sx={{ fontSize: 25, fontWeight: 500 }} />
                            </ListItemButton>
                        </ListItem>
                        <ListSubheader disableSticky sx={{bgcolor: 'transparent', fontSize: 25, mb: 5, mt: 5}}>
                            DESTEK
                        </ListSubheader>
                        <ListItem>
                            <ListItemButton sx= {{ bgcolor: OtherColorsDark[2], borderRadius: 15}}>
                                <ListItemText disableTypography id='helpitem' primary={'Yardım'} sx={{ fontSize: 25, fontWeight: 500 }} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton sx= {{ bgcolor: OtherColorsDark[2], borderRadius: 15}}>
                                <ListItemText disableTypography id='privacyitem' primary={'Gizlilik'} sx={{ fontSize: 25, fontWeight: 500 }} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Stack>
            </Container></>
    )
}