import Masonry from '@mui/lab/Masonry'
import Card from '@mui/material/Card'
import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { Link } from "react-router-dom";
import Image from './image';
import { Typography } from '@mui/material';
import { use } from 'i18next';


export default function LessonGrid(props) {
    const data = props.data;
    const heights = props.heights;
    const colors = props.colors;
    const columns = props.columns;
    const finishedlessons = props.finishedlessons;
    const url = props.url; 

    return (
        <Masonry columns={columns} spacing={0}>
            {data.length > 0 &&
            data.map((lesson, index) => (
                <Link key={lesson.id} to={url} state={{ lesson: lesson.data }} style={{ textDecoration: 'none' }}>
                    <Card key={lesson.id} sx={{ height: heights[index % 14], backgroundColor: colors[index % 14], borderRadius: 0, position: 'relative', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Stack key={lesson.id} spacing={4} sx={{alignItems: 'center'}}>
                            <Image image={lesson.data.image} />
                            <p style={{ fontSize: 24, fontWeight: 'bold', textAlign: 'center' }}>{lesson.data.name}</p>
                        </Stack>
                        {finishedlessons.includes(lesson.data.order) && 
                        <div style={{ position: 'absolute', bottom: 0, textAlign: 'right', marginRight: '10px', marginBottom: '5px', width: '100%', fontSize: 28}}>
                        ✓
                        </div>}
                    </Card>
                </Link>
            ))}
        </Masonry>
    );
}
