import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import React, { useState, useEffect } from 'react';
import styles from './nolives.module.css'
import { Colors, LightColors } from '../util/constants';
import CountDownTimer from './countdowntimer';

export default function NoLives(props) {

    const profile = props.profile;
    const [time, setTime] = useState(0);

    useEffect(() => {
        const time = profile.lastliveslossdate;
        setTime(time);
    }, []);

    function increaseOrder() {
        let order = props.order;
        order++;
        props.onNextExercise(order);
    }

    return(
        <Stack spacing={25} sx={{textAlign: 'center', marginTop:'64px'}}>
        <h2>Hiç canın kalmadı</h2>
        <Stack spacing={5}>
            <h3>Sonraki ❤️:</h3>
            <CountDownTimer time={time} onFinish={increaseOrder} />
        </Stack>
        <Button className={styles.continuebutton} onClick={props.exit} sx={{bgcolor: Colors[0], color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 15, width: 500, height: 50, ':hover': {
            bgcolor: LightColors[0]
          }}}>Devam</Button>
        </Stack>
    )
}