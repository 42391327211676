import Stack from '@mui/material/Stack';
import React, { useState, useEffect } from 'react';
import VocabularyCard from './vocabularycard';
import ContinueButton from './continuebutton';
import correctsound from '../assets/sounds/correct.mp3';

export default function Vocabulary(props) {

    const word = props.word;
    const color = props.color;
    const [correctaudio, setCorrectAudio] = useState();
    const soundenabled = props.soundenabled;

    useEffect(() => {
        if (soundenabled) {
            setCorrectAudio(new Audio(correctsound));
          }
    }, []);

    function increaseOrder() {
        let order = props.order;
        order++;
        props.onNextExercise(order);
        if (correctaudio != null) {
            correctaudio.play();
          }
    }

    return(
        <Stack spacing={10}>
        <h2>Click for translation.</h2>
        <VocabularyCard word={word} color={color} />
        <ContinueButton color={color} onClick={increaseOrder} />
        </Stack>
    )
}