import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import React, { useState, useEffect } from 'react';
import correctsound from '../assets/sounds/correct.mp3';
import incorrectsound from '../assets/sounds/notcorrect.mp3';
import selectsound from '../assets/sounds/select.mp3';
import styles from './fill.module.css';

export default function Fill(props) {

  const word = props.word;
  const wordname = word.name;
  const color = props.color;
  const languagecode = props.languagecode;
  const soundenabled = props.soundenabled;

  const [wordtext, setWordText] = useState(wordname);
  const [rightletters, setRightLetters] = useState([]);
  const [letters, setLetters] = useState([]);
  const [correctaudio, setCorrectAudio] = useState();
  const [incorrectaudio, setInCorrectAudio] = useState();
  const [selectaudio, setSelectAudio] = useState();
  const [index, setIndex] = useState(0);
  const [itemclicked, setItemClicked] = useState(-1);

  useEffect(() => {
    let availableletters = [];
    switch (languagecode) {
      case "en": 
      availableletters = "abcdefghijklmnopqrstuvwxyz".split("");
        break;
      case "tr":
        availableletters = "abcçdefgğhıijklmnoöprsştuüvyz".split("");
        break;
      default:
        availableletters = "abcdefghijklmnopqrstuvwxyz".split("");
    }

    const missingcount = wordname.length < 3 ? wordname.length : 3;
    let _rightletters = wordname.slice(wordname.length - missingcount).split("");
    _rightletters = _rightletters.map(x => (x == " ") ? "⎵" : x);
    let wrongletters = availableletters.filter(x => !_rightletters.includes(x));
    wrongletters = shuffle(wrongletters).slice(0, missingcount * 2);
    setRightLetters(_rightletters);

    let _letters = [];

    for (let i=0; i < missingcount; i++) {
      let letterset = [];
      letterset.push(_rightletters[i]);
      letterset.push(wrongletters[i]);
      letterset.push(wrongletters[i+1]);
      letterset = shuffle(letterset);
      _letters.push(letterset);
    }

    setLetters(_letters);

    const wordtext = wordname.length < 4 ? "_".repeat(wordname.length) : wordname.substring(0, wordname.length - 3) + "___"
    setWordText(wordtext)

    if (soundenabled) {
      setCorrectAudio(new Audio(correctsound));
      setInCorrectAudio(new Audio(incorrectsound));
      setSelectAudio(new Audio(selectsound));
    }

  }, []);

  function increaseOrder(iswrong) {
      let order = props.order;
      order++;
      props.onNextExercise(order, iswrong, "fill");
  }

  function checkAnswer(selectedletter, selectedbutton) {
    if (selectedletter == rightletters[index]) {
      setWordText(wordtext.replace('_', selectedletter))
      if (index < rightletters.length - 1) {
        if (selectaudio != null) {
          selectaudio.play();
        }
        setIndex(index + 1);
      }
      else {
        if (correctaudio != null) {
          correctaudio.play();
        }
        increaseOrder();
      }
    }
    else {
      if (incorrectaudio != null) {
        incorrectaudio.play();
      }
      setItemClicked(selectedbutton);
      setTimeout(function () {
        increaseOrder(true);
      }, 600);
    }
  }

  function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    while (currentIndex != 0) {

      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

    return(
        <Stack spacing={15}>
        <h2>Click for translation.</h2>
            <h2>{word.translation}</h2>
            <h1>{wordtext}</h1>
        {letters.length > 0 && 
        <Stack direction="row" sx={{ justifyContent: 'space-around'}}>
            <Button className={styles.fillbutton + (itemclicked == 0 ? " " + styles.wrong : "")} sx={{backgroundColor: color + '.dark', color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 1, width: 64, height: 64, ':hover': {
          bgcolor: color + '.light'
        }}} onClick={(e) => checkAnswer(letters[index][0], 0, e)}>{letters[index][0]}</Button>
            <Button className={styles.fillbutton + (itemclicked == 1 ? " " + styles.wrong : "")} sx={{backgroundColor: color + '.dark', color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 1, width: 64, height: 64, ':hover': {
          bgcolor: color + '.light'
        }}} onClick={(e) => checkAnswer(letters[index][1], 1, e)}>{letters[index][1]}</Button>
            <Button className={styles.fillbutton + (itemclicked == 2 ? " " + styles.wrong : "")} sx={{backgroundColor: color + '.dark', color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 1, width: 64, height: 64, ':hover': {
          bgcolor: color + '.light'
        }}} onClick={(e) => checkAnswer(letters[index][2], 2, e)}>{letters[index][2]}</Button>
          </Stack>
      }
        </Stack>
    )
}