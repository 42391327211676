import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import React, { useState, useEffect } from 'react';
import styles from './endoflesson.module.css'
import { useNavigate } from "react-router-dom";
import ContinueButton from './continuebutton';
import CircularProgress from '@mui/material/CircularProgress';

export default function EndOfLesson(props) {

    const user = props.user;

    useEffect(() => {
    }, []);

    function increaseOrder() {
        let order = props.order;
        order++;
        props.onNextExercise(order);
    }

    return(
        <Stack spacing={10} sx={{ alignItems:'center', marginTop:'128px' }}>
        <Box className={styles.endoflessonbox} sx={{
            height: 600,
            width: 500,
            borderRadius: 1,
            backgroundColor: 'success.dark',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'top'
          }}>
        <Stack justifyContent='space-between'>
        <Stack sx={{ textAlign: 'center'}} spacing={10}>
        <Box sx={{ position: 'relative' }}>
            <CircularProgress
                variant="determinate"
                sx={{
                color: 'info.main',
                marginTop: '-55px'
                }}
                size={100}
                thickness={4}
                value={100}
            />
            <CircularProgress
                variant="determinate"
                sx={{
                color: 'primary',
                marginTop: '-55px',
                position: 'absolute',
                animationDuration: '550ms',
                left: 23,
                zIndex: 2,
                }}
                size={100}
                thickness={4}
                value={75}
            />
            <img className={styles.badge} src={require('../assets/icons/badgetraveler.png')}></img>
        </Box>
        <h1>Turist</h1>
        </Stack>
        <Stack>
        <h1>56</h1>
         <h2>Kelime Adedi</h2>   
         </Stack>            
        </Stack>
        </Box>
        <ContinueButton color='success' onClick={increaseOrder} />
        </Stack>
    )
}