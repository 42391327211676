import LessonGrid from '../components/lessongrid'
import NavBar from '../components/navbar'
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from "../util/authcontext";
import db from '../util/firebase';
import { query, collection, getDocs, orderBy } from "firebase/firestore";
import { useMediaQuery } from '../util/usemediaquery';
import { Colors } from '../util/constants';

const heights = [400, 280, 400, 280, 300, 400, 600, 280, 220, 240, 460, 280, 460, 400];

export default function PhraseBook() {
    const { uservalue, languagevalue } = useContext(AuthContext);
    const [user, setUser] = uservalue;
    const [language, setLanguage] = languagevalue;
    const [data, setData] = useState([]);
    const widescreen = useMediaQuery('(min-width: 900px)');

    const fetchData = async () => {
        const documentname = "phrasetopics" + language?.languagecode + language?.sourcelanguagecode
        const q = query(collection(db, documentname), orderBy("order"));
        const querySnapshot = await getDocs(q);
        const result = querySnapshot.docs.map(doc => ({id: doc.id, data: doc.data()}));
        setData(result);  
     }
     useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
        {user?.authuser != null && language?.languagecode != null &&
        <><NavBar languagecode={language?.languagecode} avatarurl={user?.authuser?.photoURL} />
        <LessonGrid data={data} url={'/phrases'} finishedlessons={[]} heights={heights} colors={Colors} columns={widescreen ? 2 : 1}>
        </LessonGrid></>
        }
        </>
    )
}
