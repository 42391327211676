import { React, useEffect, useState } from 'react';

export default function(props) {

    const [imagesrc, setImageSrc] = useState("");

    useEffect(() => {
        let image_path = '';  
        try {  
            image_path = require('../assets/' + props.image + '.png'); 
            setImageSrc(image_path);
        } catch (err) {  
            image_path = require('../assets/hello.png'); 
            setImageSrc(image_path);
        }  
    }, []);

    return(
        <img src={imagesrc} width="128" height="128" alt={props.image}>
        </img>
    )
}