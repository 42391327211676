import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ContinueButton from './continuebutton';
import React, { useState, useEffect } from 'react';
import correctsound from '../assets/sounds/correct.mp3';

export default function NewGame(props) {
    const color = props.color;
    const lesson = props.lesson;
    const [correctaudio, setCorrectAudio] = useState();
    const soundenabled = props.soundenabled;

    function increaseOrder() {
        props.onNextExercise(0);
        if (correctaudio != null) {
            correctaudio.play();
          }
    }

    useEffect(() => {
        if (soundenabled) {
            setCorrectAudio(new Audio(correctsound));
          }
    }, []);

    return(
        <Stack sx={{alignItems: 'center'}} spacing={4}>
            <h2>{lesson.name}</h2>
        <Box sx={{
            height: 450,
            width: 500,
            backgroundColor: 'primary',
            display: 'flex',
            justifyContent: 'center'
          }}>
              <Stack sx={{ alignItems: 'center', mt: 10}} spacing={8}>
        <img src={require('../assets/' + lesson.image + '.png')} width="192">
        </img>
              <h3>{lesson.explanation}</h3>
              </Stack>
          </Box>
          <ContinueButton color={color} onClick={increaseOrder} />
          </Stack>
    )
}