import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useSpeechSynthesis } from 'react-speech-kit';
import React, { useState, useEffect } from 'react';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import correctsound from '../assets/sounds/correct.mp3';
import incorrectsound from '../assets/sounds/notcorrect.mp3';
import selectsound from '../assets/sounds/select.mp3';
import styles from './wordpuzzle.module.css'

export default function WordPuzzle(props) {
  
  const word = props.word;
  const wordname = word.name;
  const color = props.color;
  const languagecode = props.languagecode;
  const soundenabled = props.soundenabled;

  const [rightletters, setRightLetters] = useState([]);
  const [letters, setLetters] = useState([]);
  const [answer, setAnswer] = useState("");
  const [correctaudio, setCorrectAudio] = useState();
  const [incorrectaudio, setInCorrectAudio] = useState();
  const [selectaudio, setSelectAudio] = useState();
  const [index, setIndex] = useState(0);
  const [itemclicked, setItemClicked] = useState(-1);
  const [wronganswered, setWrongAnswered] = useState(false);
  const [disabledbuttons, setDisabledButtons] = useState([]);

  useEffect(() => {
    let availableletters = [];
    switch (languagecode) {
      case "en": 
      availableletters = "abcdefghijklmnopqrstuvwxyz".split("");
        break;
      case "tr":
        availableletters = "abcçdefgğhıijklmnoöprsştuüvyz".split("");
        break;
      default:
        availableletters = "abcdefghijklmnopqrstuvwxyz".split("");
    }

    let _rightletters = wordname.split("");
    _rightletters = _rightletters.map(x => (x == " ") ? "⎵" : x);
    let wrongletters = availableletters.filter(x => !_rightletters.includes(x));
    wrongletters = shuffle(wrongletters).slice(0, 9 - _rightletters.length);
    setRightLetters(_rightletters);

    let _letters = _rightletters.concat(wrongletters);
    _letters = shuffle(_letters);

    setLetters(_letters);

    let _answer = (_rightletters.length > 9 ) ? _rightletters.slice(0, _rightletters.length - 9).join("") + "_".repeat(9)  : "_".repeat(wordname.length);

    setAnswer(_answer);

    if (soundenabled) {
      setCorrectAudio(new Audio(correctsound));
      setInCorrectAudio(new Audio(incorrectsound));
      setSelectAudio(new Audio(selectsound));
    }
  }, []);

  function increaseOrder(iswrong) {
      let order = props.order;
      order++;
      props.onNextExercise(order, iswrong, "wordpuzzle");
  }

  function checkAnswer(selectedletter, selectedbutton) {
    setItemClicked(selectedbutton);
    let previouslydisabledbuttons = disabledbuttons;
    previouslydisabledbuttons.push(selectedbutton);
    setDisabledButtons(previouslydisabledbuttons);
    if (selectedletter == rightletters[index]) {
      if (index < rightletters.length - 1) {
        if (selectaudio != null) {
          selectaudio.play();
        }
        setAnswer(answer.slice(0, index) + selectedletter + answer.slice(index + 1));
        setIndex(index + 1);
      }
      else {
        if (correctaudio != null) {
          correctaudio.play();
        }
        setAnswer(answer.slice(0, index) + selectedletter);
        setTimeout(function () {
          increaseOrder();
        }, 100);
      }
    }
    else {
      if (incorrectaudio != null) {
        incorrectaudio.play();
      }
      setWrongAnswered(true);
      setTimeout(function () {
        increaseOrder(true);
      }, 600);
    }
  }

  function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    while (currentIndex != 0) {

      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

    return(
        <Stack spacing={10}>
        <h2>Click for translation.</h2>
            <h2>{word.translation}</h2>
            <h1>{answer}</h1>
            {letters.length > 0 && 
            <Stack spacing={2}>
        <Stack direction="row" sx={{ justifyContent: 'space-around'}}>
        <Button disabled={disabledbuttons.includes(0)} className={styles.wordpuzzlebutton + ((itemclicked == 0 && wronganswered) ? " " + styles.wrong : "")} sx={{bgcolor: color + '.dark', color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 1, width: 64, height: 64, ':hover': {
      bgcolor: color + '.light'
    }}} onClick={(e) => checkAnswer(letters[0], 0, e)}>{letters[0]}</Button>
        <Button disabled={disabledbuttons.includes(1)} className={styles.wordpuzzlebutton + ((itemclicked == 1 && wronganswered) ? " " + styles.wrong : "")} sx={{bgcolor: color + '.dark', color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 1, width: 64, height: 64, ':hover': {
      bgcolor: color + '.light'
    }}} onClick={(e) => checkAnswer(letters[1], 1, e)}>{letters[1]}</Button>
        <Button disabled={disabledbuttons.includes(2)} className={styles.wordpuzzlebutton + ((itemclicked == 2 && wronganswered) ? " " + styles.wrong : "")} sx={{bgcolor: color + '.dark', color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 1, width: 64, height: 64, ':hover': {
      bgcolor: color + '.light'
    }}} onClick={(e) => checkAnswer(letters[2], 2, e)}>{letters[2]}</Button>
        </Stack>
        <Stack direction="row" sx={{ justifyContent: 'space-around'}}>
        <Button disabled={disabledbuttons.includes(3)} className={styles.wordpuzzlebutton + ((itemclicked == 3 && wronganswered) ? " " + styles.wrong : "")} sx={{bgcolor: color + '.dark', color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 1, width: 64, height: 64, ':hover': {
      bgcolor: color + '.light'
    }}} onClick={(e) => checkAnswer(letters[3], 3, e)}>{letters[3]}</Button>
        <Button disabled={disabledbuttons.includes(4)} className={styles.wordpuzzlebutton + ((itemclicked == 4 && wronganswered) ? " " + styles.wrong : "")} sx={{bgcolor: color + '.dark', color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 1, width: 64, height: 64, ':hover': {
      bgcolor: color + '.light'
    }}} onClick={(e) => checkAnswer(letters[4], 4, e)}>{letters[4]}</Button>
        <Button disabled={disabledbuttons.includes(5)} className={styles.wordpuzzlebutton + ((itemclicked == 5 && wronganswered) ? " " + styles.wrong : "")} sx={{bgcolor: color + '.dark', color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 1, width: 64, height: 64, ':hover': {
      bgcolor: color + '.light'
    }}} onClick={(e) => checkAnswer(letters[5], 5, e)}>{letters[5]}</Button>
        </Stack>
        <Stack direction="row" sx={{ justifyContent: 'space-around'}}>
        <Button disabled={disabledbuttons.includes(6)} className={styles.wordpuzzlebutton + ((itemclicked == 6 && wronganswered) ? " " + styles.wrong : "")} sx={{bgcolor: color + '.dark', color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 1, width: 64, height: 64, ':hover': {
      bgcolor: color + '.light'
    }}} onClick={(e) => checkAnswer(letters[6], 6, e)}>{letters[6]}</Button>
        <Button disabled={disabledbuttons.includes(7)} className={styles.wordpuzzlebutton + ((itemclicked == 7 && wronganswered) ? " " + styles.wrong : "")} sx={{bgcolor: color + '.dark', color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 1, width: 64, height: 64, ':hover': {
      bgcolor: color + '.light'
    }}} onClick={(e) => checkAnswer(letters[7], 7, e)}>{letters[7]}</Button>
        <Button disabled={disabledbuttons.includes(8)} className={styles.wordpuzzlebutton + ((itemclicked == 8 && wronganswered) ? " " + styles.wrong : "")} sx={{bgcolor: color + '.dark', color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 1, width: 64, height: 64, ':hover': {
      bgcolor: color + '.light'
    }}} onClick={(e) => checkAnswer(letters[8], 8, e)}>{letters[8]}</Button>
        </Stack>
        </Stack>
            }
        </Stack>
    )
}