import LessonGrid from '../components/lessongrid'
import NavBar from '../components/navbar'
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from "../util/authcontext";
import db from '../util/firebase';
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { useMediaQuery } from '../util/usemediaquery';
import { Colors } from '../util/constants';

const heights = [400, 280, 400, 280, 300, 400, 600, 280, 220, 240, 460, 280, 460, 400];

export default function Home() {
    const { uservalue, languagevalue } = useContext(AuthContext);
    const [user, setUser] = uservalue;
    const [language, setLanguage] = languagevalue;
    const [data, setData] = useState([]);
    const widescreen = useMediaQuery('(min-width: 900px)');

    const fetchData = async () => {
        const documentname = "lessons" + language?.languagecode + language?.sourcelanguagecode
        const q = query(collection(db, documentname), orderBy("order"));
        const querySnapshot = await getDocs(q);
        const result = querySnapshot.docs.map(doc => ({id: doc.id, data: doc.data()}));
        setData(result);  
     }

    useEffect(() => {
        fetchData();
    }, []);

    return(
        <>
        {user?.profile != null && 
            <><NavBar languagecode={language?.languagecode} avatarurl={user?.authuser?.photoURL} /><LessonGrid data={data} url={'/game'} finishedlessons={user?.profile?.finishedlessons} heights={heights} colors={Colors} columns={widescreen ? 4 : 2}>
                </LessonGrid></>
        }
        </>
    )
}
