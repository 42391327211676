import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Colors, DarkColors, LightColors, TintColor, OtherColors, OtherColorsDark } from '../util/constants';
import { useLocation } from 'react-router-dom';
import ExitButton from '../components/exitbutton';
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import db from '../util/firebase';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import styles from './phrases.module.css';
import { useSpeechSynthesis } from 'react-speech-kit';
import AuthContext from "../util/authcontext";
import Image from '../components/image';

const theme = createTheme({
    palette: {
      primary: {
        main: Colors[0],        
        dark: DarkColors[0],
        light: LightColors[0],
      },
      secondary: {
        main: Colors[5],        
        dark: DarkColors[1],
        light: LightColors[0],
      },
      error: {
        main: Colors[6],        
        dark: DarkColors[2],
        light: LightColors[0],
      },
      warning: {
        main: Colors[8],        
        dark: DarkColors[3],
        light: LightColors[0],
      },
      success: {
        main: OtherColors[1],
        dark: OtherColorsDark[1], 
        light: LightColors[0] 
      },
      info: {
        main: TintColor
      }
    },
  });

export default function Phrases() {

    const { uservalue, languagevalue } = useContext(AuthContext);
    const [language, setLanguage] = languagevalue;
    const location = useLocation();
    const { lesson } = location.state;
    const order = lesson.order;
    const [palette, setPalette] = useState('primary');
    const [phrases, setPhrases] = useState([]);
    const { speak } = useSpeechSynthesis();

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const documentname = "phrases" + language?.languagecode + language?.sourcelanguagecode
            const q = query(collection(db, documentname), where("topicid", "==", lesson.order), orderBy("order"));
            const querySnapshot = await getDocs(q);
            const result = querySnapshot.docs.map(doc => doc.data());
            setPhrases(result);  
         }
         fetchData();
    }, []);

    useEffect(() => {

        switch (order % 4) {
            case 0: 
            setPalette('primary');
            document.body.style.background = theme.palette.primary.main;
            break;
            case 1: 
            setPalette('secondary');
            document.body.style.background = theme.palette.secondary.main;
            break;
            case 2: 
            setPalette('error');
            document.body.style.background = theme.palette.error.main;
            break;
            case 3: 
            setPalette('warning');
            document.body.style.background = theme.palette.warning.main;
            break;
            default: 
            setPalette('success');
            document.body.style.background = theme.palette.success.main;
        }
      }, []);

    function exit() {
      navigate("/phrasebook");
    }

    return (     
        <ThemeProvider theme={theme}>   
        <ExitButton color={palette + '.dark'} onExit={exit} />
        <Container sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Stack sx={{alignItems: 'center', width: '80%' }} spacing={4}>
            <h2>{lesson.name}</h2>
        <Box sx={{
            height: 250,
            width: 400,
            backgroundColor: 'primary',
            display: 'flex',
            justifyContent: 'center'
          }}>
            <Image image={lesson.image} />
          </Box>
          <Box sx={{width: '100%'}}>
            {phrases.map((phrase) => {
                return (
                    <Accordion defaultExpanded={phrase.order === 1} className={styles.accordionitem} TransitionProps={{ unmountOnExit: true }} onChange={() => speak({ text: phrase.name })}
                        key={phrase.name} sx= {{ bgcolor: palette + '.dark', width: '100%', mt:2, pl: 5}}>
                        <AccordionSummary>
                        <Typography variant='h6'>{phrase.translation}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography variant='h5'>
                            {phrase.name}
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
            </Box>
          </Stack>
          </Container>
          </ThemeProvider>
    )
}