import React, { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

// Configure Firebase.
const config = {
    apiKey: "AIzaSyCGAY0cicggcz7qXHy3uLx0ktsZy9ehjHQ",
    authDomain: "dopa-d454d.firebaseapp.com",
    projectId: "dopa-d454d",
    storageBucket: "dopa-d454d.appspot.com",
    messagingSenderId: "832476491572",
    appId: "1:832476491572:web:da92fd6df1e28aa1b03f61",
    measurementId: "G-NWH15ECD15"
};
firebase.initializeApp(config);

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    {
      provider: 'apple.com', 
    },
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ],
};

export default function Login() {
  useEffect(() => {    
    var ui = new firebaseui.auth.AuthUI(firebase.auth());
    // The start method will wait until the DOM is loaded.
    ui.start('#firebaseui-auth-container', uiConfig);
    document.body.style.background = '#A3F4E2';
  }, []);

  return (
    <Container sx={{ marginTop: 20}}>
        <Stack spacing={5}>
        <h2>Log in to save your progress</h2>
        <div id="firebaseui-auth-container"></div>
        </Stack>
    </Container>
  )
}
