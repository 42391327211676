import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import ContinueButton from './continuebutton';
import youtubeicon from '../assets/icons/youtube.svg';
import instagramicon from '../assets/icons/instagram.svg';
import styles from './socialmedia.module.css';

export default function EndOfLesson(props) {

    const navigate = useNavigate();

    const [instagramvisited, setInstagramVisited] = useState(localStorage.getItem("Instagram_Visited") ?? "false");
    const [youtubevisited, setYoutubeVisited] = useState(localStorage.getItem("Youtube_Visited") ?? "false");

    useEffect(() => {
        if (instagramvisited === "false") {
            localStorage.setItem("Instagram_Visited", "true");
        }
        if (instagramvisited === "true" && youtubevisited === "false") {
            localStorage.setItem("Youtube_Visited", "true");
        }
        if (instagramvisited === "true" && youtubevisited === "true") {
            navigate("/", { replace: true });
        }
    }, []);

    if (instagramvisited === "false") {
     
    return(
        <Stack spacing={20} sx={{ alignItems:'center', marginTop:'32px' }}>
            <h2>Günlük paylaşımlar için Instagram hesabımızı takip edebilirsiniz</h2>
            <img src={instagramicon} width="192">
                        </img>
        <Button className={styles.socialmediabutton} href="https://www.instagram.com/fonoyayinlari/" sx={{bgcolor: 'success.dark', color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 15, width: 500, height: 50, ':hover': {
            bgcolor: 'success.light'
          }}}>Devam</Button>
        </Stack>
    )   
    }

    else if (instagramvisited === "true" && youtubevisited === "false") {

        return(
            <Stack spacing={20} sx={{ alignItems:'center', marginTop:'32px' }}>
                <h2>Ders videoları için Youtube hesabımızı takip edebilirsiniz</h2>
                <img src={youtubeicon} width="192">
                            </img>
            <Button className={styles.socialmediabutton} href="https://www.youtube.com/channel/UCvLNTmPcdN4fYGetF_gzRPg" sx={{bgcolor: 'success.dark', color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 15, width: 500, height: 50, ':hover': {
                bgcolor: 'success.light'
            }}}>Devam</Button>
            </Stack>
        )
    }
    else {
        return null
    }
}