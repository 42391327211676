import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React, { useState, useEffect } from 'react';
import styles from './vocabularycard.module.css';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import IconButton from '@mui/material/IconButton';
import { useSpeechSynthesis } from 'react-speech-kit';

export default function VocabularyCard(props) {
    const word = props.word;
    const color = props.color;

    const [flipped, setFlipped] = useState(false);

    useEffect(() => {

    }, []);

    function flip() {
      setFlipped(!flipped);
    }
    
    return (
    <div onClick={flip} className={styles.cardcontainer + (flipped ? " " + styles.flipped : "")}>
        <Front word={word} color={color} />
        <Back word={word} color={color} />
    </div>

    )
}
  
  function Front(props) {
    const { speak } = useSpeechSynthesis();
    const word = props.word;
    const color = props.color;

      return (
        <Box className={styles.front} sx={{
            height: 450,
            width: 500,
            borderRadius: 1,
            backgroundColor: color + '.dark',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
        <Stack sx={{ alignItems: 'center' }} spacing={6}>
        <img className={styles.wordimage} src={require('../assets/' + word.image + '.png')}>
                        </img>
                        <IconButton size="large" onClick={() => speak({ text: word.name })}>
        <PlayCircleIcon fontSize="inherit" />
        </IconButton>
            <h2>{word.name}</h2>
        </Stack>
        </Box>
      )
  }
  
  function Back(props) {
    const word = props.word;
    const color = props.color;

      return (
        <Box className={styles.back} sx={{
            height: 450,
            width: 500,
            borderRadius: 1,
            backgroundColor: color + '.dark'
          }}>
            <h2>{word.translation}</h2>
        </Box>
      )
  }
  

  