import React, { useState, useEffect, useContext } from 'react';
import NewGame from '../components/newgame';
import Vocabulary from '../components/vocabulary';
import YesNo from '../components/yesno';
import SelectImage from '../components/selectimage';
import Fill from '../components/fill';
import WordPuzzle from '../components/wordpuzzle';
import Speech from '../components/speech';
import EndOfLesson from '../components/endoflesson';
import SocialMedia from '../components/socialmedia';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Colors, DarkColors, LightColors, TintColor, OtherColors, OtherColorsDark, MAX_LIVES } from '../util/constants';
import { collection, getDocs, query, where } from "firebase/firestore";
import db from '../util/firebase';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import AuthContext from "../util/authcontext";
import GamePanel from '../components/gamepanel';
import ExitButton from '../components/exitbutton';
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import NoLives from '../components/nolives';

const theme = createTheme({
    palette: {
      primary: {
        main: Colors[0],        
        dark: DarkColors[0],
        light: LightColors[0],
      },
      secondary: {
        main: Colors[5],        
        dark: DarkColors[1],
        light: LightColors[0],
      },
      error: {
        main: Colors[6],        
        dark: DarkColors[2],
        light: LightColors[0],
      },
      warning: {
        main: Colors[8],        
        dark: DarkColors[3],
        light: LightColors[0],
      },
      success: {
        main: OtherColors[1],
        dark: OtherColorsDark[1], 
        light: LightColors[0] 
      },
      info: {
        main: TintColor
      }
    },
  });

export default function Game() {
    const languagecode = "en";
    const location = useLocation();
    const { lesson } = location.state;
    const [exerciseorder, setExerciseOrder] = useState(0);
    const [wordindex, setWordIndex] = useState(0);
    const [palette, setPalette] = useState('primary');
    const [words, setWords] = useState([]);
    const [word, setWord] = useState({});
    const [remainingwords, setRemainingWords] = useState([]);
    const [nextexercise, setNextExercise] = useState("");
    const { uservalue, languagevalue } = useContext(AuthContext);
    const [user, setUser] = uservalue;
    const [language, setLanguage] = languagevalue;

    const navigate = useNavigate();
    const soundenabled = (localStorage.getItem("Sound_Enabled") ?? "true") === "true" ? true : false;
    const speechenabled = (localStorage.getItem("Speech_Enabled") ?? "true") === "true" ? true : false;

    function goToNextExercise(neworder, iswrong = false, exercise = null) { 

        let profile = user.profile;

        if (iswrong) {
            if (profile.lives > 0) {
                profile.lives--;
            }

            if (exercise != null) {
                let _remainingwords = remainingwords;

                _remainingwords.push(exercise);
                setRemainingWords(_remainingwords);
            }

            profile.lastliveslossdate = Date.now();

            setUser({ authuser: user.authuser, profile: profile });
            updateProfile(user.authuser.uid, profile)
        }

        let nextexercise = "";
        let newwordindex = wordindex;
        let currentword = "";

        if (profile.lives == 0) {
            nextexercise = "nolives";
        }
        else {
            if (neworder == 0) {
                neworder = 1
            }

            nextexercise = decideNextExercise(neworder, words).nextexercise
            neworder = decideNextExercise(neworder, words).neworder
            currentword = decideNextExercise(neworder, words).currentword

            if (nextexercise == "speech") {
                if (!speechenabled) {
                    neworder += 1;
                    nextexercise = decideNextExercise(neworder, words).nextexercise
                    neworder = decideNextExercise(neworder, words).neworder
                    currentword = decideNextExercise(neworder, words).currentword
                }
            }
        }
        
        if (nextexercise == "vocabulary" && neworder > 1) {
            newwordindex += 1
            setWordIndex(newwordindex)
        }

        if (newwordindex > words.length - 1 && nextexercise != "endoflesson" && nextexercise != "socialmedia") {
            nextexercise = "endoflesson"
            neworder = 1000
        }

        if (currentword != "") {
            setWord(currentword);
        }
        else {
            setWord(words[newwordindex]);
        }

        if (neworder == 1000) {
            let profile = user?.profile;
            const finishedlessons = profile?.finishedlessons;
            const lessonid = lesson.order;
            if (!finishedlessons.includes(lessonid)) {
                profile.percentage += lesson.frequency;
                profile.vocabularyCount += words.length;
                profile.finishedlessons.push(lessonid);
                updateProfile(user.authuser.uid, profile, true);
            }
        }
        else if (!iswrong) {
            const newlives = updateLives();
            if (newlives != profile.lives) {
                profile.lives = newlives
                setUser({ authuser: user.authuser, profile: profile });
                updateProfile(user.authuser.uid, profile);
            }
        }

        setNextExercise(nextexercise);
        setExerciseOrder(neworder);
    }

    const updateProfile = async(userid, profile, islast = false) => {
        const docRef = doc(db, "users", userid, "profiles", languagecode);

        if (islast) {
            await updateDoc(docRef, {
                finishedlessons: arrayUnion(lesson.order),
                percentage: profile.percentage,
                vocabularyCount: profile.vocabularyCount
            });
        }
        else {
            await updateDoc(docRef, {
                lives: profile?.lives,
                lastliveslossdate: profile?.lastliveslossdate
            });
        }
    }

    function decideNextExercise(neworder, words) {
        let lessonorder = lesson.order
        let nextexercise = ""
        let currentword = ""

/*         if (lessonorder < 25) {
            switch (neworder) {
                case 1: 
                    nextexercise = "vocabulary";
                    break;
                case 2: 
                    nextexercise = "selectimage";
                    break;
                case 3: 
                    nextexercise = "vocabulary";
                    break;
                case 4: 
                    nextexercise = "selectimage";
                    break;
                case 5: 
                    nextexercise = "vocabulary";
                    break;
                case 6: 
                    nextexercise = "selectimage";
                    break;
                case 7: 
                    nextexercise = "vocabulary";
                    break;
                case 8: 
                    nextexercise = "selectimage";
                    break;
                case 9: 
                    nextexercise = "vocabulary";
                    break;
                case 10: 
                    nextexercise = "selectimage";
                    break;
                case 11: 
                    nextexercise = "vocabulary";
                    break;
                case 12: 
                    nextexercise = "selectimage";
                    break;
                case 13: 
                    nextexercise = "vocabulary";
                    break;
                case 14: 
                    nextexercise = "selectimage";
                    break;
                case 15: 
                    nextexercise = "vocabulary";
                    break;
                case 16: 
                    nextexercise = "selectimage";
                    break;
                case 17: 
                    if (remainingwords.length > 0) {
                        ({ nextexercise, currentword, neworder } = getRemainingWord(nextexercise, currentword, neworder));
                        break;
                    }
                    else {
                        nextexercise = "endoflesson";
                        neworder = 1000;
                        break;
                    }
                case 1001: 
                    nextexercise = "socialmedia";
                    break;
                default:
                    break;
            }
        } */
        if (lessonorder % 3 == 1) {
            switch (neworder) {
                case 1: 
                    nextexercise = "vocabulary";
                    currentword = words[0]
                    break;
                case 2: 
                    nextexercise = "yesno";
                    currentword = words[0]
                    break;
                case 3: 
                    nextexercise = "vocabulary";
                    currentword = words[1]
                    break;
                case 4: 
                    nextexercise = "selectimage";
                    currentword = words[0]
                    break;
                case 5:
                    nextexercise = "yesno";
                    currentword = words[1]
                    break;
                case 6:
                    nextexercise = "fill";
                    currentword = words[0]
                    break;
                case 7:
                    nextexercise = "wordpuzzle";
                    currentword = words[1]
                    break;
                case 8: 
                    nextexercise = "vocabulary";
                    currentword = words[2]
                    break;
                case 9:
                    nextexercise = "fill";
                    currentword = words[2]
                    break;
                case 10: 
                    nextexercise = "selectimage";
                    currentword = words[1]
                    break;
                case 11: 
                    nextexercise = "speech";
                    currentword = words[0]
                    break;
                case 12: 
                    nextexercise = "vocabulary";
                    currentword = words[3]
                    break;
                case 13: 
                    nextexercise = "selectimage";
                    currentword = words[2]
                    break;
                case 14: 
                    nextexercise = "yesno";
                    currentword = words[3]
                    break;
                case 15:
                    nextexercise = "wordpuzzle";
                    currentword = words[2]
                    break;
                case 16:
                    nextexercise = "speech";
                    currentword = words[1]
                    break;
                case 17: 
                    nextexercise = "vocabulary";
                    currentword = words[4]
                    break;
                case 18:
                    nextexercise = "fill";
                    currentword = words[4]
                    break;
                case 19: 
                    nextexercise = "yesno";
                    currentword = words[4]
                    break;
                case 20: 
                    nextexercise = "selectimage";
                    currentword = words[3]
                    break;
                case 21: 
                    nextexercise = "speech";
                    currentword = words[3]
                    break;
                case 22: 
                    nextexercise = "vocabulary";
                    currentword = words[5]
                    break;
                case 23: 
                    nextexercise = "yesno";
                    currentword = words[5]
                    break;
                case 24:
                    nextexercise = "wordpuzzle";
                    currentword = words[5]
                    break;
                case 25: 
                    nextexercise = "speech";
                    currentword = words[2]
                    break;
                case 26: 
                    nextexercise = "speech";
                    currentword = words[4]
                    break;
                case 27: 
                    nextexercise = "vocabulary";
                    currentword = words[6]
                    break;
                case 28: 
                    nextexercise = "yesno";
                    currentword = words[6]
                    break;
                case 29: 
                    nextexercise = "selectimage";
                    currentword = words[3]
                    break;
                case 30: 
                    nextexercise = "yesno";
                    currentword = words[5]
                    break;
                case 31:
                    nextexercise = "speech";
                    currentword = words[5]
                    break;
                case 32:
                    nextexercise = "wordpuzzle";
                    currentword = words[6]
                    break;
                case 33:
                    nextexercise = "fill";
                    currentword = words[4]
                    break;
                case 34:
                    nextexercise = "speech";
                    currentword = words[6]
                    break;
                case 35: 
                    nextexercise = "vocabulary";
                    currentword = words[7]
                    break;
                case 36: 
                    nextexercise = "selectimage";
                    currentword = words[7]
                    break;
                case 37:
                    nextexercise = "fill";
                    currentword = words[7]
                    break;
                case 38:
                    nextexercise = "speech";
                    currentword = words[7]
                    break;
                case 39:
                    nextexercise = "selectimage";
                    currentword = words[6]
                    break;
                case 40:
                    nextexercise = "wordpuzzle";
                    currentword = words[7]
                    break;
                case 41: 
                    if (remainingwords.length > 0) {
                        ({ nextexercise, currentword, neworder } = getRemainingWord(nextexercise, currentword, neworder));
                        break;
                    }
                    else {
                        nextexercise = "endoflesson";
                        neworder = 1000;
                        break;
                    }
                case 1001: 
                    nextexercise = "socialmedia";
                    break;
                default:
                    break;
            }
        }
        else if (lessonorder % 3 == 2) {
            switch (neworder) {
                case 1: 
                    nextexercise = "vocabulary";
                    currentword = words[0]
                    break;
                case 2: 
                    nextexercise = "yesno";
                    currentword = words[0]
                    break;
                case 3: 
                    nextexercise = "vocabulary";
                    currentword = words[1]
                    break;
                case 4: 
                    nextexercise = "selectimage";
                    currentword = words[0]
                    break;
                case 5:
                    nextexercise = "yesno";
                    currentword = words[1]
                    break;
                case 6:
                    nextexercise = "fill";
                    currentword = words[0]
                    break;
                case 7:
                    nextexercise = "wordpuzzle";
                    currentword = words[1]
                    break;
                case 8: 
                    nextexercise = "vocabulary";
                    currentword = words[2]
                    break;
                case 9:
                    nextexercise = "fill";
                    currentword = words[2]
                    break;
                case 10: 
                    nextexercise = "selectimage";
                    currentword = words[1]
                    break;
                case 11: 
                    nextexercise = "speech";
                    currentword = words[0]
                    break;
                case 12: 
                    nextexercise = "vocabulary";
                    currentword = words[3]
                    break;
                case 13: 
                    nextexercise = "selectimage";
                    currentword = words[2]
                    break;
                case 14: 
                    nextexercise = "yesno";
                    currentword = words[3]
                    break;
                case 15:
                    nextexercise = "wordpuzzle";
                    currentword = words[2]
                    break;
                case 16:
                    nextexercise = "speech";
                    currentword = words[1]
                    break;
                case 17: 
                    nextexercise = "vocabulary";
                    currentword = words[4]
                    break;
                case 18:
                    nextexercise = "fill";
                    currentword = words[4]
                    break;
                case 19: 
                    nextexercise = "yesno";
                    currentword = words[4]
                    break;
                case 20: 
                    nextexercise = "selectimage";
                    currentword = words[3]
                    break;
                case 21: 
                    nextexercise = "speech";
                    currentword = words[3]
                    break;
                case 22: 
                    nextexercise = "vocabulary";
                    currentword = words[5]
                    break;
                case 23: 
                    nextexercise = "yesno";
                    currentword = words[5]
                    break;
                case 24:
                    nextexercise = "wordpuzzle";
                    currentword = words[5]
                    break;
                case 25: 
                    nextexercise = "speech";
                    currentword = words[2]
                    break;
                case 26: 
                    nextexercise = "speech";
                    currentword = words[4]
                    break;
                case 27: 
                    nextexercise = "vocabulary";
                    currentword = words[6]
                    break;
                case 28: 
                    nextexercise = "yesno";
                    currentword = words[6]
                    break;
                case 29: 
                    nextexercise = "selectimage";
                    currentword = words[3]
                    break;
                case 30: 
                    nextexercise = "yesno";
                    currentword = words[5]
                    break;
                case 31:
                    nextexercise = "speech";
                    currentword = words[5]
                    break;
                case 32:
                    nextexercise = "wordpuzzle";
                    currentword = words[6]
                    break;
                case 33:
                    nextexercise = "fill";
                    currentword = words[4]
                    break;
                case 34:
                    nextexercise = "speech";
                    currentword = words[6]
                    break;
                case 35: 
                    nextexercise = "vocabulary";
                    currentword = words[7]
                    break;
                case 36: 
                    nextexercise = "selectimage";
                    currentword = words[7]
                    break;
                case 37:
                    nextexercise = "fill";
                    currentword = words[7]
                    break;
                case 38:
                    nextexercise = "speech";
                    currentword = words[7]
                    break;
                case 39:
                    nextexercise = "selectimage";
                    currentword = words[6]
                    break;
                case 40:
                    nextexercise = "wordpuzzle";
                    currentword = words[7]
                    break;
                case 41: 
                    if (remainingwords.length > 0) {
                        ({ nextexercise, currentword, neworder } = getRemainingWord(nextexercise, currentword, neworder));
                        break;
                    }
                    else {
                        nextexercise = "endoflesson";
                        neworder = 1000;
                        break;
                    }
                case 1001: 
                    nextexercise = "socialmedia";
                    break;
                default:
                    break;
            }
        }
        else {
            switch (neworder) {
                case 1: 
                    nextexercise = "vocabulary";
                    currentword = words[0]
                    break;
                case 2: 
                    nextexercise = "yesno";
                    currentword = words[0]
                    break;
                case 3: 
                    nextexercise = "vocabulary";
                    currentword = words[1]
                    break;
                case 4: 
                    nextexercise = "selectimage";
                    currentword = words[0]
                    break;
                case 5:
                    nextexercise = "yesno";
                    currentword = words[1]
                    break;
                case 6:
                    nextexercise = "fill";
                    currentword = words[0]
                    break;
                case 7:
                    nextexercise = "wordpuzzle";
                    currentword = words[1]
                    break;
                case 8: 
                    nextexercise = "vocabulary";
                    currentword = words[2]
                    break;
                case 9:
                    nextexercise = "fill";
                    currentword = words[2]
                    break;
                case 10: 
                    nextexercise = "selectimage";
                    currentword = words[1]
                    break;
                case 11: 
                    nextexercise = "speech";
                    currentword = words[0]
                    break;
                case 12: 
                    nextexercise = "vocabulary";
                    currentword = words[3]
                    break;
                case 13: 
                    nextexercise = "selectimage";
                    currentword = words[2]
                    break;
                case 14: 
                    nextexercise = "yesno";
                    currentword = words[3]
                    break;
                case 15:
                    nextexercise = "wordpuzzle";
                    currentword = words[2]
                    break;
                case 16:
                    nextexercise = "speech";
                    currentword = words[1]
                    break;
                case 17: 
                    nextexercise = "vocabulary";
                    currentword = words[4]
                    break;
                case 18:
                    nextexercise = "fill";
                    currentword = words[4]
                    break;
                case 19: 
                    nextexercise = "yesno";
                    currentword = words[4]
                    break;
                case 20: 
                    nextexercise = "selectimage";
                    currentword = words[3]
                    break;
                case 21: 
                    nextexercise = "speech";
                    currentword = words[3]
                    break;
                case 22: 
                    nextexercise = "vocabulary";
                    currentword = words[5]
                    break;
                case 23: 
                    nextexercise = "yesno";
                    currentword = words[5]
                    break;
                case 24:
                    nextexercise = "wordpuzzle";
                    currentword = words[5]
                    break;
                case 25: 
                    nextexercise = "speech";
                    currentword = words[2]
                    break;
                case 26: 
                    nextexercise = "speech";
                    currentword = words[4]
                    break;
                case 27: 
                    nextexercise = "vocabulary";
                    currentword = words[6]
                    break;
                case 28: 
                    nextexercise = "yesno";
                    currentword = words[6]
                    break;
                case 29: 
                    nextexercise = "selectimage";
                    currentword = words[3]
                    break;
                case 30: 
                    nextexercise = "yesno";
                    currentword = words[5]
                    break;
                case 31:
                    nextexercise = "speech";
                    currentword = words[5]
                    break;
                case 32:
                    nextexercise = "wordpuzzle";
                    currentword = words[6]
                    break;
                case 33:
                    nextexercise = "fill";
                    currentword = words[4]
                    break;
                case 34:
                    nextexercise = "speech";
                    currentword = words[6]
                    break;
                case 35: 
                    nextexercise = "vocabulary";
                    currentword = words[7]
                    break;
                case 36: 
                    nextexercise = "selectimage";
                    currentword = words[7]
                    break;
                case 37:
                    nextexercise = "fill";
                    currentword = words[7]
                    break;
                case 38:
                    nextexercise = "speech";
                    currentword = words[7]
                    break;
                case 39:
                    nextexercise = "selectimage";
                    currentword = words[6]
                    break;
                case 40:
                    nextexercise = "wordpuzzle";
                    currentword = words[7]
                    break;
                case 41: 
                    if (remainingwords.length > 0) {
                        ({ nextexercise, currentword, neworder } = getRemainingWord(nextexercise, currentword, neworder));
                        break;
                    }
                    else {
                        nextexercise = "endoflesson";
                        neworder = 1000;
                        break;
                    }
                case 1001: 
                    nextexercise = "socialmedia";
                    break;
                default:
                    break;
            }
        }

        return { nextexercise, neworder, currentword }
    }

    function getRemainingWord(nextexercise, currentword, neworder) {
        const remainingword = remainingwords.shift();
        nextexercise = remainingword.exercise;
        currentword = remainingword.word;
        neworder -= 1;
        return { nextexercise, currentword, neworder };
    }

    function updateLives() {
        let profile = user.profile
        let lives = profile.lives
        let livestoadd = 0

        if (lives < MAX_LIVES) {
            const hours = Math.floor((Date.now() - profile.lastliveslossdate) / (1000 * 60 * 60));

            if (hours >= 2 && hours < 4) {
                livestoadd = 1;
            }
            else if (hours >= 4 && hours < 6) {
                livestoadd = 2;
            }
            else if (hours >= 6 && hours < 8) {
                livestoadd = 3;
            }
            else if (hours >= 8) {
                livestoadd = 4;
            }

            let newlives = lives + livestoadd;

            if (newlives > MAX_LIVES) {
                newlives = MAX_LIVES;
            }

            return newlives
        }

        return MAX_LIVES
    }

    function exit() {
        navigate("/");
    }

    useEffect(() => {
        const fetchData = async () => {
            const documentname = "words" + language?.languagecode + language?.sourcelanguagecode
            const q = query(collection(db, documentname), where("lessonid", "==", lesson.order));
            const querySnapshot = await getDocs(q);
            const result = querySnapshot.docs.map(doc => doc.data());
            setWords(result);  
         }
       
         fetchData();
    }, []);

    useEffect(() => {
        if (exerciseorder >= 1000) {
            setPalette('success');
            document.body.style.background = theme.palette.success.main; 
        }
        else if (nextexercise === "nolives") {
            document.body.style.background = OtherColors[0];
        }
        else {
        switch (exerciseorder % 4) {
            case 0: 
            setPalette('primary');
            document.body.style.background = theme.palette.primary.main;
            break;
            case 1: 
            setPalette('secondary');
            document.body.style.background = theme.palette.secondary.main;
            break;
            case 2: 
            setPalette('error');
            document.body.style.background = theme.palette.error.main;
            break;
            case 3: 
            setPalette('warning');
            document.body.style.background = theme.palette.warning.main;
            break;
            default: 
            setPalette('success');
            document.body.style.background = theme.palette.success.main;
        }
        }
      }, [exerciseorder]);


    if (exerciseorder === 0) {
        return (
            <ThemeProvider theme={theme}>
                <ExitButton color={palette + '.dark'} onExit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} className="game-container">
                    <NewGame color={palette} lesson={lesson} order={exerciseorder} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                </Container>
            </ThemeProvider>
        )
    }
    else if (nextexercise === "nolives") {
        return (
            <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} className="game-container">
                <NoLives profile={user?.profile} soundenabled={soundenabled} onNextExercise={goToNextExercise} exit={exit} />
            </Container>
        )
    }
    else if (nextexercise === "vocabulary") {
        return (
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={user?.profile?.lives} wordindex={wordindex} words={words} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} className="game-container">
                    <Vocabulary languagecode={languagecode} color={palette} order={exerciseorder} word={word} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                </Container>
            </ThemeProvider>
        )
    }
    else if (nextexercise === "yesno") {
        return (
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={user?.profile?.lives} wordindex={wordindex} words={words} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} className="game-container">
                    <YesNo languagecode={languagecode} color={palette} order={exerciseorder} words={words} word={word} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                </Container>
            </ThemeProvider>
        )
    }
    else if (nextexercise === "selectimage") {
        return (
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={user?.profile?.lives} wordindex={wordindex} words={words} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} className="game-container">
                    <SelectImage languagecode={languagecode} color={palette} order={exerciseorder} words={words} word={word} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                </Container>
            </ThemeProvider>
        )
    }

    else if (nextexercise === "fill") {
        return (
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={user?.profile?.lives} wordindex={wordindex} words={words} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} className="game-container">
                    <Fill languagecode={languagecode} color={palette} order={exerciseorder} words={words} word={word} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                </Container>
            </ThemeProvider>
        )
    }

    else if (nextexercise === "wordpuzzle") {
        return (
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={user?.profile?.lives} wordindex={wordindex} words={words} exit={exit} /> 
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} className="game-container">
                    <WordPuzzle languagecode={languagecode} color={palette} order={exerciseorder} word={word} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                </Container>
            </ThemeProvider>
        )
    }

    else if (nextexercise === "speech") {
        return (
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={user?.profile?.lives} wordindex={wordindex} words={words} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} className="game-container">
                    <Speech languagecode={languagecode} color={palette} order={exerciseorder} words={words} word={word} soundenabled={soundenabled} speechenabled={speechenabled} onNextExercise={goToNextExercise} />
                </Container>
            </ThemeProvider>
        )
    }

    else if (nextexercise === "endoflesson") {
        return (
            <ThemeProvider theme={theme}>
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} className="game-container">
                    <EndOfLesson user={user} words={words} order={exerciseorder} onNextExercise={goToNextExercise} />
                </Container>
            </ThemeProvider>
        )
    }

    else if (nextexercise === "socialmedia") {
        return (
            <ThemeProvider theme={theme}>
                <ExitButton color='success.dark' onExit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} className="game-container">
                    <SocialMedia />
                </Container>
            </ThemeProvider>
        )
    }
    else {
        return null;
    }

}