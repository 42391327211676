import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import NavBar from '../components/navbar';
import { useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';
import AuthContext from "../util/authcontext";
import { getAuth } from "firebase/auth";
import { BADGE_THRESHOLDS, OtherColors } from '../util/constants';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import LinearProgress from '@mui/material/LinearProgress';

export default function Profile() {

    const navigate = useNavigate();
    const { uservalue, languagevalue } = useContext(AuthContext);
    const [user, setUser] = uservalue;
    const [language, setLanguage] = languagevalue;
    const [percentage1, setPercentage1] = useState(0.0);
    const [percentage2, setPercentage2] = useState(0.0);
    const [percentage3, setPercentage3] = useState(0.0);
    const [percentage4, setPercentage4] = useState(0.0);
    const auth = getAuth();

    function toggleSignIn() {
        if (user?.authuser) {
            auth.signOut();
        }
        else {
            navigate("/login");
        }
    }

    useEffect(() => {
        const percentage = user?.profile?.percentage;

        if (percentage <= BADGE_THRESHOLDS[0]) {
            const progress = (percentage * 100) / BADGE_THRESHOLDS[0]
            setPercentage1(progress)
        }
        else if (percentage > BADGE_THRESHOLDS[0] && percentage <= BADGE_THRESHOLDS[1]) {
            const progress = ((percentage - BADGE_THRESHOLDS[0]) * 100) / (BADGE_THRESHOLDS[1] - BADGE_THRESHOLDS[0])
            setPercentage1(100.0)
            setPercentage2 (progress)
        }
        else if (percentage > BADGE_THRESHOLDS[1] && percentage <= BADGE_THRESHOLDS[2]) {
            const progress = ((percentage - BADGE_THRESHOLDS[1]) * 100) / (BADGE_THRESHOLDS[2] - BADGE_THRESHOLDS[1])
            setPercentage1(100.0)
            setPercentage2(100.0)
            setPercentage3(progress)
        }
        else if (BADGE_THRESHOLDS[2] > 50.0) {
            const progress = ((percentage - BADGE_THRESHOLDS[2]) * 100) / (BADGE_THRESHOLDS[3] - BADGE_THRESHOLDS[2])
            setPercentage1(100.0)
            setPercentage2(100.0)
            setPercentage3(100.0)
            setPercentage4(progress)  
        }
    }, [user]);

    useEffect(() => {
        document.body.style.background = '#EDEFF6';
    }, []);

    return(
        <><NavBar languagecode={language?.languagecode} avatarurl={user?.authuser?.photoURL} />
        <Container sx={{ backgroundColor: '#EDEFF6', display:'flex', justifyContent: 'center'}}>
            <Stack spacing={5} sx={{width: '80%', justifyContent: 'center'}}>
            <Box sx={{ backgroundColor: '#C987FF', display:'flex', alignItems: 'center', padding: 1, height: 100, borderRadius: 1, marginTop: 5, cursor: 'pointer'}} onClick={toggleSignIn}>
                <Stack direction="row" spacing={4} sx={{alignItems: 'center'}}>
                    <img src={user?.authuser?.photoURL} style={{borderRadius: '50%'}} width='60' height='60'></img>
                    <Stack spacing={0}>
                    <h2>{user?.authuser?.displayName}</h2>
                    <div>Sign out</div>
                    </Stack>
                </Stack>
            </Box>
            <Box sx={{ backgroundColor: OtherColors[0], display:'flex', alignItems: 'center', padding: 1, borderRadius: 1, marginTop: 5}}>
            <List>
                <ListItem
                    key="badgeitem1">
                        <ListItemAvatar>
                            <img src={require('../assets/icons/badgetraveler.png')} width='80' height='80' alt="badge"></img>
                        </ListItemAvatar>
                        <Stack sx={{ml:10, width: '100px'}}>
                            <ListItemText id="1" primary="Tourist" primaryTypographyProps={{fontSize: '24px'}} />
                            <ListItemText id="11" secondary="Seviye" secondaryTypographyProps={{fontSize: '18px'}} />
                            <LinearProgress variant="determinate" value={percentage1} />
                        </Stack>
                </ListItem>
                <ListItem
                    key="badgeitem1" sx={{mt: 2}}>
                        <ListItemAvatar>
                            <img src={require('../assets/icons/badgetraveler.png')} width='80' height='80' alt="badge"></img>
                        </ListItemAvatar>
                        <Stack sx={{ml:10, width: '100px'}}>
                            <ListItemText id="2" primary="Tourist" primaryTypographyProps={{fontSize: '24px'}} />
                            <ListItemText id="21" secondary="Seviye" secondaryTypographyProps={{fontSize: '18px'}} />
                            <LinearProgress variant="determinate" value={percentage2} />
                        </Stack>
                </ListItem>
                <ListItem
                    key="badgeitem1" sx={{mt: 2}}>
                        <ListItemAvatar>
                            <img src={require('../assets/icons/badgetraveler.png')} width='80' height='80' alt="badge"></img>
                        </ListItemAvatar>
                        <Stack sx={{ml:10, width: '100px'}}>
                            <ListItemText id="3" primary="Tourist" primaryTypographyProps={{fontSize: '24px'}} />
                            <ListItemText id="31" secondary="Seviye" secondaryTypographyProps={{fontSize: '18px'}} />
                            <LinearProgress variant="determinate" value={percentage3} />
                        </Stack>
                </ListItem>
                <ListItem
                    key="badgeitem1" sx={{mt: 2}}>
                        <ListItemAvatar>
                            <img src={require('../assets/icons/badgetraveler.png')} width='80' height='80' alt="badge"></img>
                        </ListItemAvatar>
                        <Stack sx={{ml:10, width: '100px'}}>
                            <ListItemText id="4" primary="Tourist" primaryTypographyProps={{fontSize: '24px'}} />
                            <ListItemText id="41" secondary="Seviye" secondaryTypographyProps={{fontSize: '18px'}} />
                            <LinearProgress variant="determinate" value={percentage4} />
                        </Stack>
                </ListItem>
            </List>  
        </Box>   
        </Stack>
    </Container></>
    )
}